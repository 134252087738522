<div class="wrapper">
    <div class="content container">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-10 offset-lg-1 justify-content-lg-center signin">
              <div class="scroll-containers" >
                  <h1 *ngIf="agenceSelected!=null" style="text-align: center;" >Faite votre Pre-inscription chez <span>{{agenceSelected.name}}</span> </h1>
                  <app-tabindex-tpl [navItems]="navItems">
                      <app-content-tpl
                        title="Nouvelle inscription"
                        [enable-element-header-actions]="[elementHeaderAction.back]"
                        [element-box-tp]="true"
                        (click-on-back)="goBack()"
                        element-box-title="Formulaire"
                        element-box-desc="Ajouter une nouvelle pré-inscriptions"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <p-checkbox
                                [(ngModel)]="newRegistration"
                                [binary]="true"
                                label="Nouvelle pré-inscriptions"
                                name="new_registration"
                                [disabled]="true"
                              ></p-checkbox>
                            </div>
                          </div>
                        </div>
                    
                        <ng-container *ngIf="newRegistration === false">
                          <form
                            class="form-inline"
                            [formGroup]="formFilter"
                            (ngSubmit)="submitFilter()"
                          >
                            <select
                              formControlName="agence_academic_year_id"
                              class="form-control mr-2"
                            >
                              <option value="">[ Année académique ]</option>
                              <ng-container
                                *ngIf="agenceAcademicYears$ | async as agenceAcademicYears"
                              >
                                <option
                                  *ngFor="let agenceAcademicYear of agenceAcademicYears"
                                  [value]="agenceAcademicYear.id"
                                >
                                  {{ agenceAcademicYear.academic_year.name }}
                                </option>
                              </ng-container>
                            </select>
                            <p-autoComplete
                              formControlName="classe"
                              field="name"
                              (completeMethod)="searchClasse($event)"
                              placeholder="Classe"
                              [suggestions]="classrooms"
                              [unique]="true"
                            ></p-autoComplete>
                            <input
                              formControlName="nom"
                              name="nom"
                              id="nom"
                              class="form-control ml-2 mr-2"
                              placeholder="Nom de famille"
                              pInputText
                              required
                            />
                            <p-button
                              [disabled]="formFilter.invalid || isFilterLoading"
                              styleClass="ui-button-raised ui-button-primary ml-2"
                              type="submit"
                              icon="os-icon os-icon-search2"
                              label="Rechercher"
                            ></p-button>
                            <p-button
                              [disabled]="isFilterLoading"
                              styleClass="ui-button-raised ml-2"
                              type="reset"
                              icon="os-icon os-icon-close"
                            ></p-button>
                          </form>
                    
                          <div
                            *ngIf="registrations$ | async as registrations"
                            class="table-responsive mt-3"
                          >
                            <table id="dataTable" style="width: 100%" class="table table-padded">
                              <thead>
                                <tr>
                                  <th>Date d'inscription</th>
                                  <th class="text-center">Code élève</th>
                                  <th>Nom de famille</th>
                                  <th>Prénoms</th>
                                  <th>Classe</th>
                                  <th class="text-right">Actions</th>
                                </tr>
                              </thead>
                    
                              <ng-container *ngIf="registrations?.length > 0">
                                <tbody>
                                  <tr *ngFor="let registration of registrations">
                                    <td class="nowrap">
                                      <span>{{
                                        registration.created | date : "dd/MM/yyyy HH:mm"
                                      }}</span>
                                    </td>
                                    <td class="nowrap text-center">
                                      <span>{{ registration.student.code }}</span>
                                    </td>
                                    <td class="nowrap">
                                      <span>{{ registration.student.nom }}</span>
                                    </td>
                                    <td class="nowrap">
                                      <span>{{ registration.student.prenoms }}</span>
                                    </td>
                                    <td class="nowrap">
                                      <span>{{ registration.classroom.name }}</span>
                                    </td>
                                    <td class="nowrap text-right actions">
                                      <p-button
                                        [routerLink]="[
                                          '/admin/inscriptions/reinscription',
                                          registration.id
                                        ]"
                                        type="button"
                                        label="Re-Inscription"
                                        styleClass="ui-button-raised ui-button-primary mr-2"
                                        icon="os-icon os-icon-users"
                                      ></p-button>
                                    </td>
                                  </tr>
                                </tbody>
                              </ng-container>
                    
                              <ng-container *ngIf="registrations?.length === 0">
                                <tbody>
                                  <tr>
                                    <td colspan="10" class="text-center">
                                      Aucune donnée disponible correspondant à vos critères.
                                    </td>
                                  </tr>
                                </tbody>
                              </ng-container>
                    
                              <ng-container *ngIf="errorData">
                                <tbody>
                                  <tr>
                                    <td colspan="10" class="text-center">
                                      <span class="pi pi-times-circle mr-2"></span>
                                      Impossible de charger vos données.
                                    </td>
                                  </tr>
                                </tbody>
                              </ng-container>
                            </table>
                          </div>
                        </ng-container>
                    
                        <form
                          *ngIf="newRegistration === true"
                          [formGroup]="form"
                          (ngSubmit)="submit()"
                        >
                          <div class="row">
                            <div class="col-12">
                              <p-panel
                                header="IDENTIFICATION"
                                formGroupName="student"
                                [toggleable]="true"
                                [collapsed]="true"
                              >
                                <div class="row">
                                  <div class="col-12">
                                    <div class="form-group">
                                      <input
                                        class="custom-radio mr-2"
                                        type="radio"
                                        formControlName="sexe"
                                        value="m"
                                        id="masculin"
                                      /><label for="masculin">Masculin</label>
                                      <input
                                        class="custom-radio ml-3 mr-2"
                                        type="radio"
                                        formControlName="sexe"
                                        value="f"
                                        id="feminin"
                                      /><label for="feminin">F&eacute;minin</label>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div
                                      class="form-group"
                                      [ngClass]="{ 'has-error has-danger': isInvalid(nom) }"
                                    >
                                      <label class="label" for="nom">
                                        Nom
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <input
                                        formControlName="nom"
                                        id="nom"
                                        name="nom"
                                        class="form-control"
                                        placeholder="Saisir le nom de famille"
                                        pInputText
                                      />
                                      <div
                                        *ngIf="isInvalid(nom)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="nom.errors?.required">Champs obligatoire.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div
                                      class="form-group"
                                      [ngClass]="{ 'has-error has-danger': isInvalid(prenoms) }"
                                    >
                                      <label class="label" for="prenoms">
                                        Prénoms
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <input
                                        formControlName="prenoms"
                                        id="prenoms"
                                        name="prenoms"
                                        class="form-control"
                                        placeholder="Saisir le(s) prénom(s)"
                                        pInputText
                                      />
                                      <div
                                        *ngIf="isInvalid(prenoms)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="prenoms.errors?.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div
                                      class="form-group"
                                      [ngClass]="{ 'has-error has-danger': isInvalid(birthdate) }"
                                    >
                                      <label class="label" for="birthdate">
                                        Date de naissance
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <input
                                        type="date"
                                        formControlName="birthdate"
                                        id="birthdate"
                                        name="birthdate"
                                        class="form-control"
                                        placeholder="Saisir la date de naissance"
                                        pInputText
                                      />
                                      <div
                                        *ngIf="isInvalid(birthdate)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="birthdate.errors?.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div
                                      class="form-group"
                                      [ngClass]="{
                                        'has-error has-danger': isInvalid(birthplace)
                                      }"
                                    >
                                      <label class="label" for="birthplace">
                                        Lieu de naissance
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <input
                                        formControlName="birthplace"
                                        id="birthplace"
                                        name="birthplace"
                                        class="form-control"
                                        placeholder="Saisir le lieu de naissance"
                                        pInputText
                                      />
                                      <div
                                        *ngIf="isInvalid(birthplace) && birthplace.errors"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="birthplace.errors?.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="nationalite"> Nationalité </label>
                                      <input
                                        formControlName="nationalite"
                                        id="nationalite"
                                        name="nationalite"
                                        class="form-control"
                                        placeholder=""
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="adresse"> Adresse </label>
                                      <input
                                        formControlName="adresse"
                                        id="adresse"
                                        name="adresse"
                                        class="form-control"
                                        placeholder=""
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <div class="row">
                                        <div class="col-12">
                                          <label class="label" for="telephone">
                                            Numéro de téléphone
                                          </label>
                                        </div>
                                        <div class="col-12">
                                          <input
                                            formControlName="telephone"
                                            id="telephone"
                                            name="telephone"
                                            class="form-control"
                                            pInputText
                                            ng2TelInput
                                            [ng2TelInputOptions]="{ initialCountry: 'ci' }"
                                            (ng2TelOutput)="getNumber(telephone, $event)"
                                            (intlTelInputObject)="telInputObject($event)"
                                            (countryChange)="onCountryChange($event)"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="adresse_email">
                                        Adresse E-mail
                                      </label>
                                      <input
                                        formControlName="adresse_email"
                                        id="adresse_email"
                                        name="adresse_email"
                                        class="form-control"
                                        pInputText
                                        email
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p-fieldset legend="Statut">
                                      <div class="form-group">
                                        <input
                                          class="custom-radio mr-2"
                                          type="radio"
                                          formControlName="affecte"
                                          value="0"
                                          id="naff"
                                          (change)="checkAff(false)"
                                        /><label for="naff">{{ "Non affecté" | uppercase }}</label>
                                        <input
                                          class="custom-radio ml-3 mr-2"
                                          type="radio"
                                          formControlName="affecte"
                                          value="1"
                                          id="aff"
                                          (change)="checkAff(true)"
                                        /><label for="aff">{{ "Affecté" | uppercase }}</label>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="matricule">
                                          Numéro de matricule (Affecté)
                                          <span style="color: #cc0000">*</span>
                                        </label>
                                        <input
                                          formControlName="matricule"
                                          id="matricule"
                                          name="matricule"
                                          class="form-control"
                                          placeholder="Saisir le numéro de matricule affecté"
                                          pInputText
                                        />
                                        <div
                                          *ngIf="isInvalid(matricule)"
                                          class="help-block form-text with-errors form-control-feedback"
                                        >
                                          <ul class="list-unstyled">
                                            <li *ngIf="matricule.errors?.required">
                                              Champs obligatoire.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </p-fieldset>
                                  </div>
                                  <div class="col-md-6">
                                    <p-fieldset
                                      legend="Santé"
                                      [toggleable]="true"
                                      [collapsed]="true"
                                    >
                                      <div class="form-group">
                                        <label class="label" for="handicap"> Handicap </label>
                                        <select
                                          id="handicap"
                                          formControlName="handicap"
                                          class="custom-select"
                                        >
                                          <optgroup label="NON">
                                            <option value="">Aucun</option>
                                          </optgroup>
                                          <optgroup label="OUI">
                                            <option value="moteur">Moteur</option>
                                            <option value="visuel">Visuel</option>
                                            <option value="auditif">Auditif</option>
                                          </optgroup>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="gsanguin">
                                          Groupe sanguin de l'élève
                                        </label>
                                        <select
                                          id="gsanguin"
                                          formControlName="groupe_sanguin"
                                          class="custom-select"
                                        >
                                          <optgroup label="Groupe sanguin A">
                                            <option value="A+">A+</option>
                                            <option value="A-">A-</option>
                                          </optgroup>
                                          <optgroup label="Groupe sanguin B">
                                            <option value="B+">B+</option>
                                            <option value="B-">B-</option>
                                          </optgroup>
                                          <optgroup label="Groupe sanguin AB">
                                            <option value="AB+">AB+</option>
                                            <option value="AB-">AB-</option>
                                          </optgroup>
                                          <optgroup label="Groupe sanguin O">
                                            <option value="O+">O+</option>
                                            <option value="O-">O-</option>
                                          </optgroup>
                                        </select>
                                      </div>
                                    </p-fieldset>
                                  </div>
                                </div>
                    
                                <fieldset>
                                  <legend>
                                    <span class="text-uppercase"
                                      >Informations Parents et tuteur légal</span
                                    >
                                  </legend>
                    
                                  <div class="row">
                                    <ng-container formGroupName="family">
                                      <div class="col-md-4">
                                        <p-fieldset
                                          formGroupName="father"
                                          legend="Père"
                                          [toggleable]="true"
                                          [collapsed]="false"
                                        >
                                          <input
                                            type="hidden"
                                            name="fathertype"
                                            formControlName="type"
                                            value="pere"
                                          />
                                          <div class="form-group">
                                            <p-checkbox
                                              [binary]="true"
                                              label="Information non connue"
                                              name="inconnu"
                                              formControlName="inconnu"
                                              (onChange)="toggleUse('pere', $event)"
                                            ></p-checkbox>
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('father').get('nom')
                                              )
                                            }"
                                          >
                                            <label class="label" for="fathernom">
                                              Nom
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="nom"
                                              id="fathernom"
                                              name="fathernom"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('father').get('prenoms')
                                              )
                                            }"
                                          >
                                            <label class="label" for="fatherprenoms">
                                              Prénom(s)
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="prenoms"
                                              id="fatherprenoms"
                                              name="fatherprenoms"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('father').get('fonction')
                                              )
                                            }"
                                          >
                                            <label class="label" for="fatherfonction">
                                              Profession
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="fonction"
                                              id="fatherfonction"
                                              name="fatherfonction"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('father').get('adresse')
                                              )
                                            }"
                                          >
                                            <label class="label" for="fatheradresse">
                                              Adresse
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="adresse"
                                              id="fatheradresse"
                                              name="fatheradresse"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('father').get('phone')
                                              )
                                            }"
                                          >
                                            <div class="row">
                                              <div class="col-12">
                                                <label class="label" for="fatherphone">
                                                  Numéro de téléphone
                                                  <span style="color: #cc0000">*</span>
                                                </label>
                                              </div>
                                              <div class="col-12">
                                                <input
                                                  formControlName="phone"
                                                  id="fatherphone"
                                                  name="fatherphone"
                                                  class="form-control"
                                                  pInputText
                                                  ng2TelInput
                                                  [ng2TelInputOptions]="{ initialCountry: 'ci' }"
                                                  (hasError)="
                                                    hasError(
                                                      student
                                                        .get('family')
                                                        .get('father')
                                                        .get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (ng2TelOutput)="
                                                    getNumber(
                                                      student
                                                        .get('family')
                                                        .get('father')
                                                        .get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (intlTelInputObject)="telInputObject($event)"
                                                  (countryChange)="onCountryChange($event)"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </p-fieldset>
                                      </div>
                                      <div class="col-md-4">
                                        <p-fieldset
                                          formGroupName="mother"
                                          legend="Mère"
                                          [toggleable]="true"
                                          [collapsed]="false"
                                        >
                                          <input
                                            type="hidden"
                                            name="mothertype"
                                            formControlName="type"
                                            value="mere"
                                          />
                                          <div class="form-group">
                                            <p-checkbox
                                              [binary]="true"
                                              label="Information non connue"
                                              name="inconnu"
                                              formControlName="inconnu"
                                              (onChange)="toggleUse('mere', $event)"
                                            ></p-checkbox>
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('mother').get('nom')
                                              )
                                            }"
                                          >
                                            <label class="label" for="mothernom">
                                              Nom
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="nom"
                                              id="mothernom"
                                              name="mothernom"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('mother').get('prenoms')
                                              )
                                            }"
                                          >
                                            <label class="label" for="motherprenoms">
                                              Prénoms
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="prenoms"
                                              id="motherprenoms"
                                              name="motherprenoms"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('mother').get('fonction')
                                              )
                                            }"
                                          >
                                            <label class="label" for="motherfonction">
                                              Profession
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="fonction"
                                              id="motherfonction"
                                              name="motherfonction"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('mother').get('adresse')
                                              )
                                            }"
                                          >
                                            <label class="label" for="motheradresse">
                                              Adresse
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="adresse"
                                              id="motheradresse"
                                              name="motheradresse"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('family').get('mother').get('phone')
                                              )
                                            }"
                                          >
                                            <div class="row">
                                              <div class="col-12">
                                                <label class="label" for="motherphone">
                                                  Numéro de téléphone
                                                  <span style="color: #cc0000">*</span>
                                                </label>
                                              </div>
                                              <div class="col-12">
                                                <input
                                                  formControlName="phone"
                                                  id="motherphone"
                                                  name="motherphone"
                                                  class="form-control"
                                                  pInputText
                                                  ng2TelInput
                                                  [ng2TelInputOptions]="{ initialCountry: 'ci' }"
                                                  (hasError)="
                                                    hasError(
                                                      student
                                                        .get('family')
                                                        .get('mother')
                                                        .get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (ng2TelOutput)="
                                                    getNumber(
                                                      student
                                                        .get('family')
                                                        .get('mother')
                                                        .get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (intlTelInputObject)="telInputObject($event)"
                                                  (countryChange)="onCountryChange($event)"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </p-fieldset>
                                      </div>
                                    </ng-container>
                    
                                    <ng-container formGroupName="tutor">
                                      <div class="col-md-4">
                                        <p-fieldset
                                          legend="Tuteur légal"
                                          [toggleable]="true"
                                          [collapsed]="false"
                                        >
                                          <input
                                            type="hidden"
                                            name="tuteurtype"
                                            formControlName="type"
                                            value="tuteur"
                                          />
                    
                                          <div class="form-group">
                                            <ng-container
                                              *ngIf="
                                                student.get('family').get('father').get('inconnu')
                                                  .value === false
                                              "
                                            >
                                              <p-radioButton
                                                (onClick)="onTuteurCheck($event)"
                                                formControlName="who"
                                                name="who"
                                                value="pere"
                                                id="isPere"
                                              >
                                              </p-radioButton>
                                              <label class="ml-2 mr-3" for="isPere">Père</label>
                                            </ng-container>
                    
                                            <ng-container
                                              *ngIf="
                                                student.get('family').get('mother').get('inconnu')
                                                  .value === false
                                              "
                                            >
                                              <p-radioButton
                                                (onClick)="onTuteurCheck($event)"
                                                formControlName="who"
                                                name="who"
                                                value="mere"
                                                id="isMere"
                                              >
                                              </p-radioButton>
                                              <label class="ml-2 mr-3" for="isMere">Mère</label>
                                            </ng-container>
                    
                                            <p-radioButton
                                              (onClick)="onTuteurCheck($event)"
                                              formControlName="who"
                                              name="who"
                                              value="tuteur"
                                              id="isAutre"
                                            >
                                            </p-radioButton>
                                            <label class="ml-2" for="isAutre">Autre</label>
                                          </div>
                    
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('tutor').get('nom')
                                              )
                                            }"
                                          >
                                            <label class="label" for="tuteurnom">
                                              Nom
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="nom"
                                              id="tuteurnom"
                                              name="tuteurnom"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('tutor').get('prenoms')
                                              )
                                            }"
                                          >
                                            <label class="label" for="tuteurprenoms">
                                              Prénoms
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="prenoms"
                                              id="tuteurprenoms"
                                              name="tuteurprenoms"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('tutor').get('fonction')
                                              )
                                            }"
                                          >
                                            <label class="label" for="tuteurfonction">
                                              Profession
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="fonction"
                                              id="tuteurfonction"
                                              name="tuteurfonction"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('tutor').get('adresse')
                                              )
                                            }"
                                          >
                                            <label class="label" for="tuteuradresse">
                                              Adresse
                                              <span style="color: #cc0000">*</span>
                                            </label>
                                            <input
                                              formControlName="adresse"
                                              id="tuteuradresse"
                                              name="tuteuradresse"
                                              class="form-control"
                                              pInputText
                                            />
                                          </div>
                                          <div
                                            class="form-group"
                                            [ngClass]="{
                                              'has-error has-danger': isInvalid(
                                                student.get('tutor').get('phone')
                                              )
                                            }"
                                          >
                                            <div class="row">
                                              <div class="col-12">
                                                <label class="label" for="tutorphone">
                                                  Numéro de téléphone
                                                  <span style="color: #cc0000">*</span>
                                                </label>
                                              </div>
                                              <div class="col-12">
                                                <input
                                                  formControlName="phone"
                                                  id="tutorphone"
                                                  name="tutorphone"
                                                  class="form-control"
                                                  [ng2TelInputOptions]="{ initialCountry: 'ci' }"
                                                  (hasError)="
                                                    hasError(
                                                      student.get('tutor').get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (ng2TelOutput)="
                                                    getNumber(
                                                      student.get('tutor').get('phone'),
                                                      $event
                                                    )
                                                  "
                                                  (intlTelInputObject)="telInputObject($event)"
                                                  (countryChange)="onCountryChange($event)"
                                                  ng2TelInput
                                                  pInputText
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </p-fieldset>
                                      </div>
                                    </ng-container>
                    
                                    <div class="col-md-12">
                                      <p-fieldset legend="Personne en charge de la scolarité">
                                        <div class="form-group">
                                          <ng-container
                                            *ngIf="
                                              student.get('family').get('father').get('inconnu')
                                                .value === false
                                            "
                                          >
                                            <p-radioButton
                                              (onClick)="onSPCheck($event)"
                                              formControlName="scolarite_payeur"
                                              name="scolarite_payeur"
                                              value="pere"
                                              id="isPayeurPere"
                                            >
                                            </p-radioButton>
                                            <label class="ml-2 mr-3" for="isPayeurPere">Père</label>
                                          </ng-container>
                    
                                          <ng-container
                                            *ngIf="
                                              student.get('family').get('mother').get('inconnu')
                                                .value === false
                                            "
                                          >
                                            <p-radioButton
                                              (onClick)="onSPCheck($event)"
                                              formControlName="scolarite_payeur"
                                              name="scolarite_payeur"
                                              value="mere"
                                              id="isPayeurMere"
                                            >
                                            </p-radioButton>
                                            <label class="ml-2 mr-3" for="isPayeurMere">Mère</label>
                                          </ng-container>
                    
                                          <ng-container
                                            *ngIf="
                                              student.get('tutor').get('who').value === 'tuteur'
                                            "
                                          >
                                            <p-radioButton
                                              (onClick)="onSPCheck($event)"
                                              formControlName="scolarite_payeur"
                                              name="scolarite_payeur"
                                              value="tuteur"
                                              id="isPayeurTuteur"
                                            >
                                            </p-radioButton>
                                            <label class="ml-2 mr-3" for="isPayeurTuteur"
                                              >Tuteur légal</label
                                            >
                                          </ng-container>
                                        </div>
                                      </p-fieldset>
                                    </div>
                                  </div>
                                </fieldset>
                              </p-panel>
                            </div>
                          </div>
                    
                          <div class="row mt-2">
                            <div class="col-12">
                              <p-panel
                                header="SCOLARIT&Eacute; ANT&Eacute;RIEURE"
                                [toggleable]="true"
                                [collapsed]="true"
                              >
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="matricule_orientation">
                                        Matricule d'orientation
                                      </label>
                                      <input
                                        formControlName="matricule_orientation"
                                        id="matricule_orientation"
                                        name="matricule_orientation"
                                        class="form-control"
                                        placeholder="Saisir le numéro de matricule"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="matricule_menet">
                                        Matricule Menet
                                      </label>
                                      <input
                                        formControlName="matricule_menet"
                                        id="matricule_menet"
                                        name="matricule_menet"
                                        class="form-control"
                                        placeholder="Saisir le numéro de matricule Menet"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="serie_bac"> Série du BAC </label>
                                      <input
                                        formControlName="serie_bac"
                                        id="serie_bac"
                                        name="serie_bac"
                                        class="form-control"
                                        placeholder="Saisir le numéro de série"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="numero_table">
                                        Numéro de table (BAC)
                                      </label>
                                      <input
                                        formControlName="numero_table"
                                        id="numero_table"
                                        name="numero_table"
                                        class="form-control"
                                        placeholder="Saisir le numéro de table"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="etablissement_origine">
                                        Etablissement d'origine
                                      </label>
                                      <input
                                        formControlName="etablissement_origine"
                                        id="etablissement_origine"
                                        name="etablissement_origine"
                                        class="form-control"
                                        placeholder="Saisir l'établissement d'origine"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="classe_frequente">
                                        Classe fréquentée
                                      </label>
                                      <input
                                        formControlName="classe_frequente"
                                        id="classe_frequente"
                                        name="classe_frequente"
                                        class="form-control"
                                        placeholder="Saisir la classe"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="diplome_obtenu">
                                        Diplôme obtenu
                                      </label>
                                      <input
                                        formControlName="diplome_obtenu"
                                        id="diplome_obtenu"
                                        name="diplome_obtenu"
                                        class="form-control"
                                        placeholder="Saisir le diplôme"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="label" for="specialite"> Spécialité </label>
                                      <input
                                        formControlName="specialite"
                                        id="specialite"
                                        name="specialite"
                                        class="form-control"
                                        placeholder="Saisir la spécialité"
                                        pInputText
                                      />
                                    </div>
                                  </div>
                                </div>
                              </p-panel>
                            </div>
                          </div>
                    
                          <!-- <div class="row mt-2">
                            <div class="col-12">
                              <p-panel
                                header="SCOLARIT&Eacute; ACTUELLE"
                                [toggleable]="true"
                                [collapsed]="true"
                              >
                                <div *ngIf="secteurs$ | async" class="row">
                                  <div class="col-md-4">
                                    <div
                                      class="form-group"
                                      [ngClass]="{
                                        'has-error has-danger': isInvalid(niveauEtudeId)
                                      }"
                                    >
                                      <label class="label" for="niveau_etude_id">
                                        Niveau d'étude
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <select
                                        formControlName="niveau_etude_id"
                                        id="niveau_etude_id"
                                        class="custom-select"
                                        (change)="onNiveauChange()"
                                      >
                                        <optgroup
                                          *ngFor="let secteur of secteurs"
                                          [label]="secteur.name"
                                        >
                                          <option
                                            *ngFor="let niveau_etude of secteur.niveau_etudes"
                                            [value]="niveau_etude.id"
                                          >
                                            {{ niveau_etude.name }}
                                          </option>
                                        </optgroup>
                                      </select>
                                      <div
                                        *ngIf="isInvalid(niveauEtudeId)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="niveauEtudeId.errors.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div
                                      class="form-group"
                                      [ngClass]="{ 'has-error has-danger': isInvalid(filiereId) }"
                                    >
                                      <label class="label" for="filiere_id">
                                        Filière
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <select
                                        formControlName="filiere_id"
                                        id="filiere_id"
                                        class="custom-select"
                                        (change)="onFiliereChange()"
                                      >
                                        <option
                                          *ngFor="let filiere of filieres"
                                          [value]="filiere.id"
                                        >
                                          {{ filiere.name }}
                                        </option>
                                      </select>
                                      <div
                                        *ngIf="isInvalid(filiereId)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="filiereId.errors.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-4">
                                    <div
                                      class="form-group"
                                      [ngClass]="{
                                        'has-error has-danger': isInvalid(classroomId)
                                      }"
                                    >
                                      <label class="label" for="classroom_id">
                                        Classe
                                        <span style="color: #cc0000">*</span>
                                      </label>
                                      <select
                                        formControlName="classroom_id"
                                        id="classroom_id"
                                        class="custom-select"
                                      >
                                        <option
                                          *ngFor="let classroom of classrooms"
                                          [value]="classroom.id"
                                        >
                                          {{ classroom.name }}
                                        </option>
                                      </select>
                                      <div
                                        *ngIf="isInvalid(classroomId)"
                                        class="help-block form-text with-errors form-control-feedback"
                                      >
                                        <ul class="list-unstyled">
                                          <li *ngIf="classroomId.errors.required">
                                            Champs obligatoire.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <p-fieldset
                                      legend="Prise en charge"
                                      [toggleable]="true"
                                      [collapsed]="true"
                                    >
                                      <div class="form-group">
                                        <input
                                          class="custom-radio mr-2"
                                          type="radio"
                                          formControlName="prise_charge"
                                          [value]="false"
                                          id="prisecNOk"
                                          (change)="checkPriseCharge(false)"
                                        /><label for="prisecNOk">{{ "Non" | uppercase }}</label>
                                        <input
                                          class="custom-radio ml-3 mr-2"
                                          type="radio"
                                          formControlName="prise_charge"
                                          [value]="true"
                                          id="prisecOk"
                                          (change)="checkPriseCharge(true)"
                                        />
                                        <label for="prisecOk">{{ "Oui" | uppercase }}</label>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="prise_charge_origine">
                                          Origine de la prise en charge
                                          <span style="color: #cc0000">*</span>
                                        </label>
                                        <input
                                          formControlName="prise_charge_origine"
                                          id="prise_charge_origine"
                                          name="prise_charge_origine"
                                          class="form-control"
                                          pInputText
                                        />
                                        <div
                                          *ngIf="isInvalid(form.get('prise_charge_origine'))"
                                          class="help-block form-text with-errors form-control-feedback"
                                        >
                                          <ul class="list-unstyled">
                                            <li
                                              *ngIf="
                                                form.get('prise_charge_origine').errors?.required
                                              "
                                            >
                                              Champs obligatoire.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="prise_charge_montant">
                                          Montant de la prise en charge
                                          <span style="color: #cc0000">*</span>
                                        </label>
                                        <input
                                          formControlName="prise_charge_montant"
                                          id="prise_charge_montant"
                                          name="prise_charge_montant"
                                          class="form-control"
                                          pInputText
                                        />
                                        <div
                                          *ngIf="isInvalid(form.get('prise_charge_montant'))"
                                          class="help-block form-text with-errors form-control-feedback"
                                        >
                                          <ul class="list-unstyled">
                                            <li
                                              *ngIf="
                                                form.get('prise_charge_montant').errors?.required
                                              "
                                            >
                                              Champs obligatoire.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </p-fieldset>
                                  </div>
                                  <div class="col-md-6">
                                    <p-fieldset
                                      legend="Autres informations"
                                      [toggleable]="true"
                                      [collapsed]="true"
                                    >
                                      <div class="form-group">
                                        <label class="label" for="doublant"> Qualité </label>
                                        <select
                                          id="doublant"
                                          formControlName="doublant"
                                          class="custom-select"
                                        >
                                          <option [value]="0">non redoublant(e)</option>
                                          <option [value]="1">redoublant(e)</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="regime"> Régime </label>
                                        <select
                                          id="regime"
                                          formControlName="regime"
                                          class="custom-select"
                                        >
                                          <option value="jour">JOUR</option>
                                          <option value="soir">SOIR</option>
                                        </select>
                                      </div>
                                      <div class="form-group">
                                        <label class="label" for="matricule_etab">
                                          Numéro de matricule (&Eacute;tablissement)
                                        </label>
                                        <input
                                          formControlName="matricule"
                                          id="matricule_etab"
                                          name="matricule_etab"
                                          class="form-control"
                                          pInputText
                                        />
                                      </div>
                                    </p-fieldset>
                                  </div>
                                </div>
                              </p-panel>
                            </div>
                          </div> -->
                    
                          <div class="form-buttons-w">
                            <p-button
                              [disabled]="form.invalid || isLoading"
                              styleClass="ui-button-raised ui-button-primary mr-2"
                              type="submit"
                              icon="os-icon os-icon-save"
                              label="Enrégistrer"
                            ></p-button>
                            <p-progressSpinner
                              *ngIf="isLoading"
                              [style]="{ width: '40px', height: '40px' }"
                              [styleClass]="'pull-right'"
                              strokeWidth="3"
                              animationDuration=".5s"
                            ></p-progressSpinner>
                          </div>
                        </form>
                      </app-content-tpl>
                    </app-tabindex-tpl>
  
              </div>          
            </div>
          </div>
        </div>
  </div>
  
  <div class="foo" >
      <div class="row pl-3 pr-3 pt-3 pb-1 footer">
          <div class="col-md-2 pt-5 pb-5">
            <img
              src="assets/img/logo3.png"
              width=120
              alt="souclou"
            >
          </div>
          <div class="col-md-3">
            <h6 class="element-header text-white mb-3">{{'Informations légales' | uppercase}}</h6>
            <ul class="list-unstyled">
              <li>
                <a href="javascript:;">Mentions légales</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lidar.ci/wp-content/uploads/2020/08/Politiques-Securitaires.pdf"
                >
                  Politique de sécurité
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lidar.ci/wp-content/uploads/2020/09/Politique-de-confidentialite.pdf"
                >
                  Politique de confidentialité
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lidar.ci/wp-content/uploads/2020/09/Tarifs-et-conditions.pdf"
                >
                  Tarifs &amp; conditions d'utilisation
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h6 class="element-header text-white mb-3">{{'Aide' | uppercase}}</h6>
            <ul class="list-unstyled">
              <li>
                <a
                  target="_blank"
                  href="https://lidar.ci/faq_lidar/"
                >
                  FAQ
                </a>
              </li>
              <li>
                <a href="javascript:;">Fiches pratiques</a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://lidar.ci/contact/"
                >
                  Nous contacter
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h6 class="element-header text-white mb-3">{{'Trouver une agence' | uppercase}}</h6>
            <ul class="list-unstyled">
              <li>
                <a href="javascript:;">Trouver l'agence la plus proche</a>
              </li>
            </ul>
          </div>
          <div class="col-md-2">
            <h6 class="element-header text-white mb-3">{{'Nous suivre' | uppercase}}</h6>
            <div class="footer-section_reseaux-sociaux-container mt-3">
              <a
                target="_blank"
                href="https://www.facebook.com/Lidar-C%C3%B4te-dIvoire-115106270356981/"
                class="mr-2"
              >
                <!-- <img src="/rsc/contrib/image/particuliers/homepage/fb.svg" alt="facebook"> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="19"
                  viewBox="0 0 8 19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      fill="#FFF"
                      d="M8 8.83H5.356V19H1.678V8.72H0V5.787h1.678c0-1.745-1-6.422 6.286-5.715v3.45c-1.179 0-2.642-.296-2.642 2.265H8v3.042z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                target="_blank"
                href="https://twitter.com/d_lidar"
                class="mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="19"
                  viewBox="0 0 23 19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      fill="#FFF"
                      d="M7.396 19C3.95 19 .81 17.546 0 16.923c0 0 3.85.727 6.787-2.073-1.518-.518-3.342-.933-4.152-3.318.91.207.91.207 1.823-.103C2.937 10.704 1.621 9.562.81 6.763c.811.414 1.622.621 2.23.621C1.62 5.829.203 3.654 1.518.956c.914.831 4.967 5.288 9.731 4.873 0-2.28.708-4.25 2.329-5.184 1.621-.934 4.359-1.037 5.674.935 1.047.552 2.838.415 3.748 0-.405.83-1.824 2.696-2.532 3.213C20.568 5.622 19.86 19 7.396 19z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                target="_blank"
                href="javascript:;"
                class="mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="19"
                  viewBox="0 0 27 19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      fill="#FFF"
                      d="M11.713 13.004l-.002-7.59 7.296 3.808-7.294 3.782zM26.73 4.099s-.264-1.861-1.073-2.682C24.63.342 23.479.336 22.952.273 19.173 0 13.506 0 13.506 0h-.012S7.827 0 4.048.273C3.521.336 2.37.342 1.343 1.417.533 2.237.27 4.099.27 4.099S0 6.285 0 8.47v2.049c0 2.187.27 4.372.27 4.372s.264 1.862 1.073 2.681C2.37 18.65 3.72 18.616 4.32 18.73c2.16.207 9.18.271 9.18.271s5.673-.008 9.452-.281c.527-.063 1.678-.07 2.705-1.146.81-.819 1.073-2.68 1.073-2.68s.27-2.186.27-4.373V8.471c0-2.186-.27-4.372-.27-4.372z"
                    ></path>
                  </g>
                </svg>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/lidar-c%C3%B4te-d-ivoire/"
                class="mr-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="19"
                  viewBox="0 0 20 19"
                >
                  <g
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <path
                      fill="#FFFFFE"
                      d="M16.518 16.19h-2.873v-4.41c0-1.05-.02-2.403-1.494-2.403-1.496 0-1.726 1.145-1.726 2.328v4.486H7.553V7.123h2.757v1.239h.039c.384-.713 1.322-1.464 2.72-1.464 2.912 0 3.45 1.878 3.45 4.319v4.974zM4.312 5.885c-.921 0-1.667-.733-1.667-1.635 0-.901.746-1.633 1.667-1.633.92 0 1.667.732 1.667 1.633 0 .902-.747 1.635-1.667 1.635zM2.874 16.19h2.875V7.123H2.874v9.068zM17.95 0H1.43C.642 0 0 .613 0 1.37v16.26C0 18.385.641 19 1.43 19h16.52c.791 0 1.435-.614 1.435-1.37V1.37c0-.757-.644-1.37-1.435-1.37z"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
      </div>
      
        <div
          class="row copyright"
          style="color: white;"
        >
          <div class="col-md-12 text-center p-2">
            <i class="fa fa-copyright"></i>
            Copyright 2020 - Fa &amp; Fa
          </div>
        </div>
  
  </div>
</div>


