import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '@core/api';
import { CredentialService } from '@core/credential.service';
import { NiveauEtude } from '@interface/niveau-etude';
import { Registration } from '@interface/registration';
import { Response } from '@model/response';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrationsService extends AbstractService {

  constructor(
    private credentialService: CredentialService,
    private http: HttpClient
  ) {
    super();
  }

  getNiveauEtudes(params?: HttpParams): Observable<NiveauEtude[]> {
    this.endpoint = `/niveau_etudes.json`;

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        shareReplay(),
        map((response) => {
          if (response.code === 0) {
            return response.niveau_etudes;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  getList(params?: HttpParams): Observable<Registration[]> {
    this.endpoint = `/registrations.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registrations;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  getListPayed(params?: HttpParams): Observable<Registration[]> {
    this.endpoint = `/registrations/filterregistration.json`;
    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }
    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registrations;
          }
          throw new Error(response.errorMessage);
        })
      );
  }



  getList_filter(params?: HttpParams): Observable<Registration[]> {
    this.endpoint = `/registrations/filter.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registrations;
          }
          throw new Error(response.errorMessage);
        })
      );
  }


  

  getAll(data: any): Observable<any> {
    this.endpoint = `/registrations/all.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<any>(this.endpoint, data);
  }

  create(data: any): Observable<Registration> {
    this.endpoint = `/registrations.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }


  createOnOut(data: any,agence_id:any): Observable<Registration> {
    this.endpoint = `/registrations/addpreinscription.json`;

      this.endpoint = `/agences/${agence_id}${this.endpoint}`;
    

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  update(id: number, data: any): Observable<Registration> {
    this.endpoint = `/registrations/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .put<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  delete(id: number): Observable<boolean> {
    this.endpoint = `/registrations/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .delete<Response>(this.endpoint)
      .pipe(
        map((response) => {
          return response.code === 0;
        })
      );
  }

  updateTutor(id: number, data: any): Observable<Registration> {
    this.endpoint = `/registrations/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .put<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  read(id, params: HttpParams = null): Observable<Registration> {
    return this
      .get(id, params)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  readBulletin(id: number, params: HttpParams = null): Observable<Registration> {
    this.endpoint = `/registrations/${id}.json`;

    return this
      .get(id, params)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registration;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  get(id, params: HttpParams = null): Observable<Response> {
    this.endpoint = `/registrations/${id}.json`;

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
    }

    return this.http
      .get<Response>(this.endpoint, { params })
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

  // getPre_inscription(id, params: HttpParams = null): Observable<Response> {
  //   this.endpoint = `/registrations/getpreregistration/${id}.json`;

  //   if (this.credentialService.isAgence()) {
  //     this.endpoint = `/agences/${this.credentialService.credentials.user.agence_user.agence.id}${this.endpoint}`;
  //   }

  //   return this.http
  //     .get<Response>(this.endpoint, { params })
  //     .pipe(
  //       map((response) => {
  //         if (response.code === 0) {
  //           return response;
  //         }
  //         throw new Error(response.errorMessage);
  //       })
  //     );
  // }

  // tslint:disable-next-line:variable-name
  search(data: any, agence_academic_year_id?: number): Observable<Registration[]> {
    this.endpoint = `/registrations/search.json`;

    let aayi = this.credentialService.credentials.user.agence_user.agence.id;
    if (agence_academic_year_id) {
      aayi = agence_academic_year_id;
    }

    if (this.credentialService.isAgence()) {
      this.endpoint = `/agences/${aayi}${this.endpoint}`;
    }

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registrations;
          }
          throw new Error(response.errorMessage);
        })
      );
  }


  search_in_out(data: any, agence_academic_year_id?: number,agence_id?:any): Observable<Registration[]> {
    this.endpoint = `/registrations/search.json`;

    let aayi = agence_id;
    if (agence_academic_year_id) {
      aayi = agence_academic_year_id;
    }

      this.endpoint = `/agences/${aayi}${this.endpoint}`;
  

    return this.http
      .post<Response>(this.endpoint, data)
      .pipe(
        map((response) => {
          if (response.code === 0) {
            return response.registrations;
          }
          throw new Error(response.errorMessage);
        })
      );
  }

}
